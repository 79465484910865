// pkgs:

// comps:
import PagesHero from '../../components/distributed/hero/hero.comp';
import InitialSliceComponent from '../../components/base/initial/initial.comp';

// utils:
import LoginPage from "../signin/signin.page";
import VEditor from "../VEditor";
import * as React from "react";
import SplitPane, {Pane} from "react-split-pane";
import {List, Modal, notification} from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import ListComp from "./List.comp";
import {useAppSelector} from "../../redux/hooks";
import {selectUserInfo} from "../../redux/slices/initial/logic/userSlice";
import {
    setNotification
} from '../../redux/slices/initial/initial.slice';
import './style.css'
import {RootState} from "../../redux/store";
import {useDispatch} from "react-redux";

const openNotification = (placement: NotificationPlacement) => {
    notification.info({
        message: `功能上新 `,
        description: '1. 忘记密码可找回 ' +
            ' 2. 登录、注册验证码校验 ' +
            '3. 优化异常处理 ' +
            '4. 引入CDN 解决图片上传问题',
        placement,
    });
};
const LandingPage: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo)
    const {notification} = useAppSelector((state: RootState) => state.InitialSlice);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (notification) {
            openNotification('top')
            dispatch(setNotification(false))
        }


    }, [])

    return (
        <main className="home-page landing-page">
            {/*<Modal*/}
                {/*title="功能上新- 2022.06.19"*/}
                {/*visible={notification}*/}
                {/*onOk={() => dispatch(setNotification(false))}*/}
                {/*onCancel={() => dispatch(setNotification(false))}*/}
                {/*okText="确认"*/}
                {/*cancelText="取消"*/}
            {/*>*/}
               {/*<ul>*/}
                   {/*<li></li>*/}
                   {/*<li>b</li>*/}
               {/*</ul>*/}
            {/*</Modal>*/}
            {userInfo && userInfo.token ?
                <div style={{"height": '89%', "display": 'flex', backgroundColor: 'rgb(245, 248, 250)'}}>
                    <div style={{"width": '250px', flex: 'none'}}>
                        <ListComp/>
                    </div>
                    <VEditor/>
                </div>
                :
                <div style={{"height": '89%', "display": 'flex', backgroundColor: 'rgb(245, 248, 250)'}}>
                    <div style={{"width": '250px', flex: 'none'}}>
                        <ListComp/>
                    </div>
                    <VEditor/>
                </div>
                // <section style={{'width': '98%'}}>
                //     <VEditor/>
                // </section>
            }
            {/*{userInfo && userInfo.token ?*/}
                {/*<SplitPane style={{"height": '89%'}} pane1Style={{}} minSize={250}  split="vertical" draggable={true}>*/}
                    {/*<Pane>*/}
                        {/*<ListComp/>*/}
                    {/*</Pane>*/}
                    {/*<Pane>*/}
                        {/*<VEditor/>*/}
                    {/*</Pane>*/}
                {/*</SplitPane>*/}
                {/*:*/}
                {/*<SplitPane style={{"height": '89%'}} pane1Style={{}} minSize={250}  split="vertical" draggable={true}>*/}
                    {/*<Pane >*/}
                        {/*<ListComp/>*/}
                    {/*</Pane>*/}
                    {/*<Pane>*/}
                        {/*<VEditor/>*/}
                    {/*</Pane>*/}
                {/*</SplitPane>*/}
                {/*// <section style={{'width': '98%'}}>*/}
                {/*//     <VEditor/>*/}
                {/*// </section>*/}
            {/*}*/}
            {/*{userInfo && userInfo.token ?*/}
            {/*<SplitPane style={{"height": '89%'}} split="vertical" minSize={50} defaultSize={250} draggable={true}>*/}
            {/*<Pane>*/}
            {/*<ListComp></ListComp>*/}
            {/*</Pane>*/}
            {/*<Pane>*/}
            {/*<section style={{'width': '100%'}}>*/}
            {/*<VEditor/>*/}
            {/*</section>*/}
            {/*</Pane>*/}
            {/*</SplitPane>*/}
            {/*:*/}
            {/*<section style={{'width': '98%'}}>*/}
            {/*<VEditor/>*/}
            {/*</section>*/}
            {/*}*/}


        </main>
    );
};

export default LandingPage;
