import React, {useState, useEffect} from 'react';
import {Input, message} from 'antd';
import {SafetyCertificateOutlined} from '@ant-design/icons';
import session from "../auth/session";
import getBaseUrl from "../../../api/base";

interface CaptchaInputValue {
    captchaCode?: string;
    captchaKey?: string;
}

interface CaptchaInputProps {
    value?: CaptchaInputValue;
    url?: string;
    change?: string;
    onChange?: (value: CaptchaInputValue) => void;
}

/**
 * 获取验证码
 * http://localhost:8081/api/captcha
 */
const getCaptcha = async (url: string) => {
    try {
        let random = new Date().getTime() + "";
        //const data = await session.captcha(random);
        return getBaseUrl() + url + random;
    } catch (error) {
        return [];
    }
    return [];
}

const CaptchaInput: React.FC<CaptchaInputProps> = ({value = {}, change='', url='/api/captcha/?', onChange}) => {

    const [captchaCode, setCaptchaCode] = useState<string>('');
    const [captchaKey, setCaptchaKey] = useState<string>('');
    const [imageData, setImageData] = useState<string>('');

    // 触发改变
    const triggerChange = (changedValue: { captchaCode?: string; captchaKey?: string }) => {
        if (onChange) {
            onChange({captchaCode, captchaKey, ...value, ...changedValue});
        }
    };

    useEffect(() => {
        getCaptcha(url).then((data: any) => {
            setImageData(data);
        })
    }, [change]);


    // 输入框变化
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value || '';
        setCaptchaCode(code);
        triggerChange({captchaCode: code});
    }

    // 时间类型变化
    const onClickImage = () => {
        getCaptcha(url).then((data: any) => {
            setImageData(data);
        })
    };
    return (
        <span>
       <Input.Group compact>
          <Input prefix={<SafetyCertificateOutlined style={{color: "#319cff"}}/>} placeholder={"请输入验证码"}
                 onChange={onChangeInput}
                 style={{width: '75%', marginRight: 5, padding: '6.5px 11px 6.5px 11px', verticalAlign: 'middle'}}/>
                   <img style={{width: '23%', height: '35px', verticalAlign: 'middle', padding: '0px 0px 0px 0px'}}
                        src={imageData} onClick={onClickImage}/>
       </Input.Group>
    </span>
    );
};
export default CaptchaInput;
