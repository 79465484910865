import React, { useState } from 'react';
import {Modal, Button, Input, Checkbox, Tabs, message} from 'antd';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {selectUserInfo} from "../../redux/slices/initial/logic/userSlice";
import {setArticleHistoryList,setDiffParams, setDefaultActiveKey} from "../../redux/slices/initial/historySlice";
import session from "../../common/interfaces/auth/session";
import {RootState} from "../../redux/store";
import ReactDiffViewer from 'react-diff-viewer';
import Form from "antd/es/form/Form";

const {TabPane} = Tabs;

const HistoryModal: React.FC<any> = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo)
    const {articleHistoryList} = useAppSelector((state: RootState) => state.historySlice);
    const {diffParam} = useAppSelector((state: RootState) => state.historySlice);
    const {defaultActiveKey} = useAppSelector((state: RootState) => state.historySlice);

    const showModal = async (e?: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        if (userInfo && userInfo.token) {
            setIsModalVisible(true);
            const result = await session.queryHistoriesByOId(props.oId)

            if (!!result) {
                dispatch(setArticleHistoryList(result))
                if (result.length > 0)
                onChangeTab(result[0].id)
            }
        }
    };

    const handleOk = async (e?: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        e.stopPropagation();

        setIsModalVisible(false);
        dispatch(setDefaultActiveKey(''))
        //const result = await session.queryHistoryById(activeKey)

    };

    const handleCancel = (e?: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        e.stopPropagation();
        dispatch(setDefaultActiveKey(''))
        setIsModalVisible(false);
    };
    const onChangeTab = async (activeKey: string) => {
        dispatch(setDefaultActiveKey(activeKey))
        const result = await session.queryHistoryById(activeKey)
        if (!!result) {
            dispatch(setDiffParams(result))
        }
    }

    React.useEffect(() => {

    }, [])

    const toPaneList= () => {
        let arr = [];
        for (let i = 0; i < articleHistoryList.length; i++) {
            arr.push( <TabPane tab={articleHistoryList[i].shortTime} key={articleHistoryList[i].id}>

                <Button type="primary" onClick={async (e?: React.MouseEvent<HTMLElement>) => {
                   const result = await session.rollback(articleHistoryList[i].id)
                    if (!!result) {
                        props.getArticleByOId(articleHistoryList[i].oId)
                        handleOk(e);
                    }
                }}block className="ant-btn ant-btn-primary ant-btn-lg">
                    恢复到{articleHistoryList[i].shortTime}版本
                </Button>
                <ReactDiffViewer leftTitle={'历史版本'} rightTitle={'当前最新'} oldValue={diffParam.oldValue} newValue={diffParam.newValue} splitView={true} />

            </TabPane>)
        }
        return <Tabs destroyInactiveTabPane={true} tabPosition='left' onChange={onChangeTab} activeKey={defaultActiveKey === '' ? (articleHistoryList.length > 0 ? articleHistoryList[0].id : '') : defaultActiveKey}>
            {articleHistoryList ? arr : undefined}
        </Tabs>
    }
    return (
        <>
            <div onClick={showModal}>
                编辑历史
            </div>
            {/*style={{width: '1000px', height: '1000px'}}*/}
            <Modal width={1500} okText={""} zIndex={9999} title={"编辑历史 ｜ " + (articleHistoryList && articleHistoryList.length > 0 ? articleHistoryList[0].title : '')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {toPaneList()}
            </Modal>
        </>
    );
};

export default HistoryModal;
