import "vditor/dist/index.css";
import React from "react";
import Vditor from "vditor";
import {
    selectContentThemeInfo,
    selectVThemeInfo,
} from '../redux/slices/initial/initial.slice';
import {
    setVditor, setLoading
} from '../redux/slices/initial/tditorSlice';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import defaultText from "./default";
import {selectUserInfo} from "../redux/slices/initial/logic/userSlice";
import getBaseUrl from "../api/base";
import {RootState} from "../redux/store";
import {message} from "antd";
import {setTimestamp} from "../redux/slices/initial/articleSlice";

const VEditor = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo)
    const {lng} = useAppSelector((state: RootState) => state.InitialSlice);
    const {editorMode} = useAppSelector((state: RootState) => state.InitialSlice);
    const {loading} = useAppSelector((state: RootState) => state.editorSlice);
    const theme = useAppSelector(selectVThemeInfo);
    const contentTheme = useAppSelector(selectContentThemeInfo);
    const zh_CN = 'zh_CN';
    const en_US = 'en_US';




    React.useEffect(() => {
        console.log("浏览器重新渲染")
        let value = defaultText
        if (userInfo && userInfo.token) {
            // const template = stringGetter(userInfo.name + '_cacheText')
            // console.log("template =" + template)

            value = ''
        }
        const vditor = new Vditor("tditor", {
            typewriterMode: true,
            value: value,
            preview: {
                theme: {
                    current: '姹紫',
                    list: { "ant-design": "Ant Design", dark: "Dark", light: "Light", wechat: "WeChat", '姹紫': '姹紫', '橙心': '橙心' },
                    path: 'https://tditor.com/cdn/dist/css/content-theme'
                },

                markdown: {
                    toc: true,
                },
                transform(html) {
                    return html;
                },
                parse: (element: HTMLElement) => {
                    console.time('time');
                    let blockquote = element.getElementsByTagName("blockquote")
                    for (let k = 0; k < blockquote.length; k++) {
                        blockquote[k].setAttribute("class", "multiquote-1")
                    }
                    let section = element.getElementsByClassName("vditor-reset")
                    section[0].setAttribute("edit-tool", 'Tditor - 在线Markdown编辑器')
                    section[0].setAttribute("tool-website", 'https://www.tditor.com')
                    for (let j = 1; j <= 3; j++) {
                        let arr = element.getElementsByTagName('h' + j);
                        for (let i = 0; i < arr.length; i++) {
                            let span = document.createElement("span");
                            let prefixSpan = document.createElement("span");
                            let suffixSpan = document.createElement("span");
                            span.setAttribute('class', 'content')
                            prefixSpan.setAttribute('class', 'prefix')
                            suffixSpan.setAttribute('class', 'suffix')
                            // let strongs = arr[i].getElementsByTagName("strong");
                            // if (undefstrongs) {
                            //     let str = ''
                            //     for (let n = 0; n < strongs.length; n++) {
                            //         str += strongs[n].innerHTML
                            //     }
                            // }
                            //let t = document.createElement(arr[i].innerHTML);
                            // @ts-ignore
                            span.appendChild(arr[i].firstChild)
                            arr[i].innerHTML = ''
                            arr[i].appendChild(prefixSpan)
                            arr[i].appendChild(span);
                            arr[i].appendChild(suffixSpan);
                        }
                    }
                    console.timeEnd('time');

                    // return element;
                }
            },
            cache: {
                enable:userInfo && userInfo.name? true: false,
                id: (userInfo && userInfo.name || 'vditorvditor') + '_cacheText',
                after: (text: string) => {
                    if (userInfo && userInfo.token) {
                        console.log("发送同步消息")
                        dispatch(setTimestamp(text))
                    }
                 }
            },
            cdn: 'https://tditor.com/cdn',
            mode: editorMode == 'sv' ? 'sv' : editorMode == 'ir' ? 'ir' : 'wysiwyg',
            theme: theme == 'classic' ? 'classic' : 'dark',
            lang: lng == 'en_US' ? en_US : zh_CN,
            toolbar: ["emoji",
                "headings",
                "bold",
                "italic",
                "strike",
                "link",
                "|",
                "list",
                "ordered-list",
                "check",
                "outdent",
                "indent",
                "|",
                "quote",
                "line",
                "code",
                "inline-code",
                "insert-before",
                "insert-after",
                "|",
                "upload",
                "record",
                "table",
                "|",
                "undo",
                "redo",
                "|",
                "preview",
                "outline",
                "fullscreen",
                "edit-mode",
                "code-theme",
                {
                    name: "more",
                    toolbar: [
                        "both",
                        "export",
                        "preview",
                        "devtools",
                    ],
                }

                // , {
                //     name: "toHelp",
                //     tipPosition: "nw",
                //     tip: "帮助文档",
                //     icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"> <path d="M19.652 25v6c0 0.55-0.45 1-1 1h-6c-0.55 0-1-0.45-1-1v-6c0-0.55 0.45-1 1-1h6c0.55 0 1 0.45 1 1zM27.552 10c0 4.75-3.225 6.575-5.6 7.9-1.475 0.85-2.4 2.575-2.4 3.3v0c0 0.55-0.425 1.2-1 1.2h-6c-0.55 0-0.9-0.85-0.9-1.4v-1.125c0-3.025 3-5.625 5.2-6.625 1.925-0.875 2.725-1.7 2.725-3.3 0-1.4-1.825-2.65-3.85-2.65-1.125 0-2.15 0.35-2.7 0.725-0.6 0.425-1.2 1.025-2.675 2.875-0.2 0.25-0.5 0.4-0.775 0.4-0.225 0-0.425-0.075-0.625-0.2l-4.1-3.125c-0.425-0.325-0.525-0.875-0.25-1.325 2.7-4.475 6.5-6.65 11.6-6.65 5.35 0 11.35 4.275 11.35 10z"></path> </svg>',
                //     click: (e) => {
                //         window.open("/edit/1581793131")
                //     },
                // }
                ],
            height: window.innerHeight - 80,
            counter: {
                enable: true,
                max: 10240,
            },
            debugger: false,
            width: '100%',
            upload: {
                accept: 'image/*,.wav,.jpg,.png,.gif,.jpeg,.svg',
                max: 10 * 1024 * 1024,
                token: userInfo && userInfo.token ? userInfo.token : '',
                url: getBaseUrl() + '/api/upload/editor',
                linkToImgUrl: getBaseUrl() + '/api/upload/fetch',
                filename(name) {
                    return name.replace(/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g, '').replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, '').replace('/\\s/g', '')
                }
            },
            //<span className="loading"></span>
            after: () => {
                dispatch(setLoading(false))
                if (theme == 'dark') {
                    vditor.setTheme('dark', 'dark')
                } else {
                   // vditor.setTheme('classic', 'light')
                    if (editorMode == 'sv') {
                        vditor.setTheme('classic', contentTheme)
                    }
                }
                dispatch(setVditor(vditor))
                //alert(vditor.options.preview.markdown.theme)

            }
        });
    }, [lng, contentTheme]);
    return <div id="tditor" className="vditor"/>;
};

export default VEditor;
