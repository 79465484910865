import {createSlice, PayloadAction} from '@reduxjs/toolkit';


// CONFIGURE THE INITIAL STATE::
// @ts-ignore
export interface ArticleHistoryListTypes {
    articleHistoryList: ArticleHistoryTypes[],
    diffParam: DiffTypes,
    defaultActiveKey: string
}

export interface ArticleHistoryTypes {
    createTime: '',
    oId: '',
    shortTime: '',
    id: ''
}

export interface DiffTypes {
    oldValue: string,
    newValue: string,
}

// CONFIGURE THE INITIAL STATE::
// @ts-ignore
const initialState: ArticleHistoryListTypes = {
    articleHistoryList: [],
    diffParam: {
        oldValue: '',
        newValue: '',
    },
    defaultActiveKey: '',
};


// CREATE THE SLICE OF THE `Initial`::
export const editorSlice = createSlice({
    name: `historySlice`,
    initialState,
    reducers: {
        setArticleHistoryList: (state, action: PayloadAction<ArticleHistoryTypes[]>) => {
            state.articleHistoryList = action.payload;
        },

        setDiffParams: (state, action: PayloadAction<DiffTypes>) => {
            state.diffParam = action.payload;
        },
        setDefaultActiveKey: (state, action: PayloadAction<string>) => {
            state.defaultActiveKey = action.payload;
        },
    }
});

// EXPORT THE ACTIONS::
export const {setArticleHistoryList, setDiffParams, setDefaultActiveKey} = editorSlice.actions;


// EXPORT THE `Initial` REDUCER::
export default editorSlice.reducer;
