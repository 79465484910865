import React from 'react';
import {Dropdown, List, Menu, Divider, message, Popconfirm, Space, Tooltip} from 'antd';
import session from "../../common/interfaces/auth/session";
import {
    setArticleList,
    setArticleLoading,
    setEditTitle,
    setCurrentTitle,
    ArticleListTypes
} from "../../redux/slices/initial/articleSlice";
import {selectContentThemeInfo, setCurrentIdx} from "../../redux/slices/initial/initial.slice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {ArticleTypes} from "../../common/types/article.types";
import defaultText from "../default";
import {
    PlusOutlined, SettingOutlined
} from '@ant-design/icons';
import {selectUserInfo} from "../../redux/slices/initial/logic/userSlice";
import {useHistory} from "react-router";
import './slide.css'
import HistoryModal from "./HistoryModal";
import {Link} from "react-router-dom";

const ListComp: React.FC = () => {
    const dispatch = useAppDispatch();
    const {articleList} = useAppSelector((state: RootState) => state.articleSlice);
    const {currentIdx} = useAppSelector((state: RootState) => state.InitialSlice);
    const {editTitle} = useAppSelector((state: RootState) => state.articleSlice);
    const {currentTitle} = useAppSelector((state: RootState) => state.articleSlice);
    const {timestamp} = useAppSelector((state: RootState) => state.articleSlice);
    const {myEditor} = useAppSelector((state: RootState) => state.editorSlice);
    const {loading} = useAppSelector((state: RootState) => state.editorSlice);
    const userInfo = useAppSelector(selectUserInfo)
    const contentTheme = useAppSelector(selectContentThemeInfo);

    const getArticleByOId = async (oid: string) => {
        if (userInfo && userInfo.token) {
            if (oid !== 'default') {
                const result = await session.queryArticleByOId(oid)
                if (!!result) {
                    myEditor.setValue(result.content)
                    dispatch(setCurrentTitle(result.title))
                }
            } else {
                myEditor.setValue(defaultText)
            }
        }
    }

    const refresh = async (list: ArticleTypes[], currentIdx: string) => {

        if (!!list && list.length > 0 && !!myEditor) {
            //列表是否包含currentIdx

            let has = list.filter((e: ArticleTypes) => (e.oId + "") === (currentIdx + ""))
            if (!has || has.length === 0) {
                dispatch(setCurrentIdx(list[0].oId))
                getArticleByOId(list[0].oId)
                let article: ArticleTypes = list.filter((e: ArticleTypes) => (e.oId + "") === list[0].oId)[0];
                dispatch(setCurrentTitle(article.title))
            } else {
                getArticleByOId(currentIdx)
                let article: ArticleTypes = list.filter((e: ArticleTypes) => (e.oId + "") === currentIdx)[0];
                dispatch(setCurrentTitle(article.title))
            }

            myEditor.focus()
        }
    }
    const sync = async (text: string) => {
        try {
            if (userInfo && userInfo.token) {
                let article = {
                    "title": currentTitle,
                    "content": text,
                    "oId": currentIdx
                }
                if (!loading && currentIdx !== 'default') {
                    session.sync(article)
                }
            }

        } catch (e) {
            const response = (e as any)?.response // Axios异常
            message.error(
                response
                    ? `发生错误:${response.data}`
                    : `认证服务异常,请联系管理员:${e}`
            )
        }
        return
    }
    const setTditorValue = async (text: string) => {
        setTimeout(() => {

            // @ts-ignore
        }, 1);

    }
    React.useEffect(() => {
        if (!!myEditor && !loading) {
            sync(timestamp)
        }
    }, [timestamp])
    React.useEffect(() => {
        if (userInfo && userInfo.token) {

            let idx = -1;
            for (let i = 0; i < articleList.length; i++) {
                if (articleList[i].oId + "" === currentIdx) {
                    idx = i;
                }
            }
            if (idx != -1 && editTitle !== '' && articleList[idx].title !== editTitle) {
                let newList = articleList.slice(0);
                let list = newList.splice(idx, 1, {
                    ...articleList[idx],
                    title: editTitle,
                });
                if (!!list) {
                    dispatch(setArticleList(newList))
                    sync(myEditor.getValue())
                }
            }

        }
    }, [editTitle])

    React.useEffect(() => {
        const onFinish = async () => {
            try {
                if (userInfo && userInfo.token && !!myEditor) {
                    const result = await session.queryArticles()
                    if (result) {
                        result.push({
                            title: 'Hello Tditor!',
                            content: defaultText,
                            oId: 'default',
                        })
                        dispatch(setArticleList(result))
                        refresh(result, currentIdx)
                        dispatch(setArticleLoading(false))
                    }
                }

            } catch (e) {

            }
            return
        }
        onFinish()
    }, [myEditor])
    const cancel = (e?: React.MouseEvent<HTMLElement>) => {
        console.log(e);
    };
    const settingMenu = (oId: string, publishType: string) => {
        return <Menu
            items={[
                {
                    label: (
                        <HistoryModal oId={oId} getArticleByOId={getArticleByOId}/>
                    ),
                    key: '0',
                }, {
                    label: (
                        <a onClick={async () => {
                            let article = {
                                oId: oId,
                                contentTheme: contentTheme,
                                publishType: 'public'
                            }
                            await session.publish(article)

                            const result = await session.queryArticles()
                            if (result) {
                                result.push({
                                    title: 'Hello Tditor!',
                                    content: defaultText,
                                    oId: 'default',
                                })
                                dispatch(setArticleList(result))
                            } else {
                                message.error("拉取文章失败")
                            }
                        }}>发布文档</a>
                    ),
                    key: '1',
                    disabled: !publishType || publishType === '' ? false : true,
                }, {
                    label: (
                        <a onClick={async () => {
                            await session.cancel(oId)
                            const result = await session.queryArticles()
                            if (result) {
                                result.push({
                                    title: 'Hello Tditor!',
                                    content: defaultText,
                                    oId: 'default',
                                })
                                dispatch(setArticleList(result))
                            } else {
                                message.error("拉取文章失败")
                            }

                        }}>取消发布</a>
                    ),
                    key: '1',
                    disabled: !publishType || publishType === '' ? true : false,
                }, {
                    label: (
                        <a onClick={() => {
                            let article = {
                                oId: oId,
                                contentTheme: contentTheme,
                                publishType: 'public'
                            }
                            session.publish(article)
                        }}>重新发布</a>
                    ),
                    key: '1',
                    disabled: !publishType || publishType === '' ? true : false,
                }, {
                    label: (
                        <Link target="_blank" to={{
                            pathname: "/article/" + oId,
                        }}>固定链接</Link>

                    ),
                    key: '2',
                    disabled: !publishType || publishType === '' ? true : false,
                },
                {
                    type: 'divider',
                },
                {
                    label: (
                        <Popconfirm
                            title="确认删除文档么?"
                            onConfirm={(e?: React.MouseEvent<HTMLElement>) => {
                                if (!!e) {
                                    e.stopPropagation();
                                    if (userInfo && userInfo.token) {
                                        let newList = articleList.filter((e: any) => {
                                            return !(e.oId + "" === oId)
                                        });
                                        dispatch(setArticleList(newList))
                                        session.deleteArticle(oId)
                                        refresh(newList, oId)
                                    }
                                }
                            }}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div>删除文档</div>
                        </Popconfirm>
                    ),
                    key: '3',
                    danger: true,
                },
            ]}
        />
    }


    const header = (
        <section style={{'textAlign': 'center', 'height': '20px'}} onClick={async () => {
            if (userInfo && userInfo.token) {
                let list = articleList.slice(0)
                let id = new Date().getTime();
                list.unshift({
                    oId: id + "",
                    title: '未命名',
                    content: ''
                })
                dispatch(setCurrentIdx(id + ""))
                dispatch(setCurrentTitle('未命名'))
                dispatch(setEditTitle(''))
                dispatch(setArticleList(list))

                let article = {
                    "title": '未命名',
                    "content": '',
                    "oId": id + ""
                }
                try {
                    let result = await session.sync(article)
                    myEditor.setValue('')
                    myEditor.focus()
                } catch (e) {
                    message.warn("抱歉，服务升级中，新增失败")
                }

            } else {
                message.info("请您登陆后操作")
            }

        }}><h4><PlusOutlined style={{'fontSize': '15px'}}/>新建文档</h4></section>
    )


    return <div style={{
        height: window.innerHeight - 80,
        overflow: 'auto',
        width: '250px',
        //padding: '0 2px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
    }}
        //, 'backgroundColor': '#f6f8fa'
    >
        <List
            header={header}
            size={'small'}
            style={{'textAlign': 'left', 'cursor': 'pointer', 'backgroundColor': '#f5f8fa'}}
            itemLayout="vertical"
            dataSource={articleList || []}
            renderItem={(item: ArticleTypes) => {

                let style1 = {
                    'backgroundColor': '#d7dce0',
                    display: 'flex',
                    alignItems: 'center',
                    'height': '80px',
                    padding: '12px',

                };
                let style2 = {display: 'flex', alignItems: 'center', 'height': '80px', padding: '12px'}

                return <List.Item key={item.oId} style={(currentIdx + "") === (item.oId + "") ? style1 : style2}
                                  onClick={async () => {
                                      try {
                                          if (userInfo && userInfo.token) {
                                              if (item.oId !== 'default') {

                                                  const result = await session.queryArticleByOId(item.oId)
                                                  if (!!result) {

                                                      dispatch(setCurrentIdx(item.oId))
                                                      dispatch(setCurrentTitle(item.title))
                                                      dispatch(setEditTitle(''))

                                                      setTimeout(() => {
                                                          myEditor.setValue(result.content)
                                                          myEditor.focus()
                                                          // @ts-ignore
                                                      }, 1);

                                                  }
                                              } else {
                                                  myEditor.setValue(defaultText)
                                              }
                                          }

                                      } catch (e) {
                                          message.info("文章加载失败")
                                      }
                                  }
                                  }>
                    <List.Item.Meta
                        title={<Tooltip placement="topLeft" title={item.title}>{item.title}</Tooltip>}
                        description={item.updateTime}
                    />
                    <Dropdown overlay={settingMenu(item.oId, item.publishType)}>
                        <Space>
                            <span><SettingOutlined style={{'fontSize': '16px', 'cursor': 'pointer'}}/></span>
                        </Space>
                    </Dropdown>
                </List.Item>
            }
            }
        />
    </div>
}

export default ListComp
