import InitialSliceReducer from './slices/initial/initial.slice';
import EditorSlice from './slices/initial/tditorSlice';
import ArticleSlice from './slices/initial/articleSlice';
import {configureStore, ThunkAction, Action, getDefaultMiddleware, combineReducers} from '@reduxjs/toolkit';
import userReducer from '../redux/slices/initial/logic/userSlice';
import {
    persistReducer,
    persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {editorSlice} from "./slices/initial/tditorSlice";
import historySlice from "./slices/initial/historySlice";

const reducers = combineReducers({
    user: userReducer,
    InitialSlice: InitialSliceReducer,
    editorSlice: EditorSlice,
    articleSlice: ArticleSlice,
    historySlice: historySlice
})
const persistConfig = {
    key: 'root',
    storage,

    // 以下是性能优化
    // whitelist = ['navigation']
     blacklist: ['editorSlice', 'articleSlice', 'historySlice']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    // reducer: {
    //     InitialSlice: InitialSliceReducer,
    //     user: userReducer
    // },
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    }),
});
export const persistor = persistStore(store)


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
