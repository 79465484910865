// pkgs:
import {VFC, useState, FC, useEffect, useContext} from 'react';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import {Menu, Dropdown, Layout, Space, message} from 'antd'
import {
    BarsOutlined, CloudDownloadOutlined, ArrowRightOutlined, HomeOutlined, FilePdfOutlined, Html5Outlined,
    FileMarkdownOutlined, SettingOutlined, DownOutlined, SmileOutlined
} from '@ant-design/icons';

// utils:
import './style.sass';
import feedback from './feedback.svg';
import themeSvg from './theme.svg';
import {HeaderInterface} from '../../../common/interfaces/comps/header.interface';
import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectUserInfo, setUserInfo} from '../../../redux/slices/initial/logic/userSlice'
import {
    setLng, setContentTheme, selectContentThemeInfo, selectVThemeInfo
} from '../../../redux/slices/initial/initial.slice';
// comps:
import {
    setEditTitle, setCurrentTitle
} from '../../../redux/slices/initial/articleSlice';
import {RootState} from "../../../redux/store";
import {Button} from "antd";
import {TextLoop} from "react-text-loop-next";
import i18nNext from "../../../common/language";
import {useTranslation} from "react-i18next";
import {exportMarkdown, exportHTML, exportPDF} from "../../../views/export/export";
import FormInput from "../form-input/form-input.comp";

// component>>>
const Header: VFC<HeaderInterface> = ({expanded}) => {
    // preConfigured hooks:
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();
    const userInfo = useAppSelector(selectUserInfo)
    const {lng} = useAppSelector((state: RootState) => state.InitialSlice);
    const contentTheme = useAppSelector(selectContentThemeInfo);
    const theme = useAppSelector(selectVThemeInfo);
    const {editorMode} = useAppSelector((state: RootState) => state.InitialSlice);
    const {myEditor} = useAppSelector((state: RootState) => state.editorSlice);
    const {currentTitle} = useAppSelector((state: RootState) => state.articleSlice);

    React.useEffect(() => {
        i18n.changeLanguage(lng);
    }, [])
    const changeContentTheme = (contentTheme: string) => {
        if (theme == 'dark') {
            message.warn('dark模式下不支持切换内容主题')
            return
        }
        if (editorMode != 'sv') {
            message.warn('仅支持分屏预览时切换内容主题')
            return
        }
        dispatch(setContentTheme(contentTheme.trim()))
    }

    const logout = async () => {
        dispatch(setUserInfo({}))
        localStorage.removeItem("access_token");
        history.replace({pathname: '/'})
    }

    const setLang = () => {
        let lang = 'zh_CN';
        if (i18n.language === 'zh_CN') {
            lang = 'en_US';
        }
        if (i18n.language === 'en_US') {
            lang = 'zh_CN';
        }
        i18n.changeLanguage(lang);
        dispatch(setLng(lang))
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={logout}>
                <SmileOutlined/><span className="ant-btn-link">退出登录</span>
            </Menu.Item>
        </Menu>
    )

    const contentThemeMenu = (
        <Menu style={{'minWidth': '60px', 'textAlign': 'right'}}>
            <Menu.Item onClick={() => changeContentTheme('light')}>
                {contentTheme == 'light' ? <ArrowRightOutlined/> : undefined} <span className="ant-btn-link"> 默认</span>
            </Menu.Item>
            <Menu.Item onClick={() => changeContentTheme('极简黑')}>
                {contentTheme == '极简黑' ? <ArrowRightOutlined/> : undefined} <span className="ant-btn-link"> 极简黑</span>
            </Menu.Item>
            <Menu.Item onClick={() => changeContentTheme('橙心')}>
                {contentTheme == '橙心' ? <ArrowRightOutlined/> : undefined} <span className="ant-btn-link">橙心</span>
            </Menu.Item>
            <Menu.Item onClick={() => changeContentTheme('姹紫')}>
                {contentTheme == '姹紫' ? <ArrowRightOutlined/> : undefined} <span className="ant-btn-link"> 姹紫</span>
            </Menu.Item>

        </Menu>
    )
    const settingMenu = (
        <Menu>
            <Menu.Item onClick={() => exportPDF('PDF', myEditor)}>
                <FilePdfOutlined/><span> 导出PDF</span>
            </Menu.Item>
            <Menu.Item onClick={() => exportMarkdown('Markdown', myEditor)}>
                <FileMarkdownOutlined/><span> 导出Markdown</span>
            </Menu.Item>
            <Menu.Item onClick={() => exportHTML(myEditor)}>
                <Html5Outlined/><span> 导出HTML</span>
            </Menu.Item>

        </Menu>
    )
    const otherMenu = (
        <Menu
            items={[
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1654416793853">
                            Markdown教程
                        </a>
                    ),
                    key: '0',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1653999657891">
                            PR/FAQ
                        </a>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1655035915418#/">
                            更新日志
                        </a>
                    ),
                    key: '3',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1653999657891">
                            关于我们
                        </a>
                    ),
                    key: '2',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://support.qq.com/product/408537">
                            反馈&建议
                        </a>
                    ),
                    key: '4',
                }
            ]}
        />
    );

    // mobile || side menu, you might need to create a redux slice
    return (
        // depending on {expanded} so wether to view a default header or the minimal one.

        <header className="default-header">
            <div style={{height: `inherit`}}>
                <div className="header-wrapper">
                    <section className="left-wing">
                        <div className="logo">
                            <Link to="/">

                            </Link>

                        </div>
                    </section>
                    <section className="left-name">

                        <h4>
                            <span className="head-text"> {i18nNext.t('title')}</span>
                        </h4>
                    </section>
                    {expanded ? (userInfo && userInfo.token ? undefined : <section className="left-name">
                        <span>
                            <TextLoop>
                                <span style={{color: "red", fontSize: '2px'}}>登陆后自动保存</span>
                                <span style={{color: "red", fontSize: '2px'}}>登陆后体验完整上传、录音功能</span>
                            </TextLoop>{" "}
                        </span>
                    </section>) : undefined}
                    {userInfo && userInfo.token ?
                        <section style={{'flexGrow': 100}}>
                            <FormInput
                                type="text"
                                value={currentTitle === '未命名' ? '' : currentTitle}
                                label=""
                                readonly={expanded ? false : true}
                                inputName="title"
                                collectInputData={(name: string, value: string) => {
                                    if (value !== currentTitle) {
                                        dispatch(setCurrentTitle(value))
                                        dispatch(setEditTitle(value))
                                    }
                                    //setFormData({ ...formData, [name]: value })
                                }
                                }
                            />
                        </section> : undefined
                    }

                    {expanded ? (<section className="right-wing">
                        <Dropdown overlay={contentThemeMenu}>
                            <Space>
                                <span><img width="25px" src={themeSvg} alt=""/> </span>
                            </Space>
                        </Dropdown>
                    </section>) : undefined}
                    <section>
                        <Dropdown overlay={settingMenu}>
                            <Space>
                                <span><CloudDownloadOutlined style={{'fontSize': '25px'}}/></span>
                            </Space>
                        </Dropdown>
                    </section>

                    {expanded ?  <section>
                        <Space onClick={() => history.push("/settings")}>
                            <span><SettingOutlined style={{'fontSize': '25px', 'cursor': 'pointer'}}/></span>
                        </Space>
                    </section> : undefined}
                    <section>
                        <Dropdown overlay={otherMenu}>
                            <Space>
                                {/*<span><img style={{'width': '25px', 'cursor': 'pointer'}} src={feedback} alt=""/> </span>*/}
                                <BarsOutlined style={{'fontSize': '25px', 'cursor': 'pointer'}}/>
                            </Space>
                        </Dropdown>
                    </section>

                    <section>
                        {/* 右上角 */}

                        {/* routes for example or any thing else */}
                        {userInfo && userInfo.token ? <Dropdown overlay={menu}>
                                <Space>
                                    {/*<HomeOutlined style={{'fontSize': '25px'}}/>*/}
                                    <span>{'您好!' + userInfo.name}</span>
                                    <DownOutlined/>
                                </Space>
                            </Dropdown> :
                            <Button onClick={() => history.push("/signin")} type="primary">{t('login')}</Button>
                        }
                    </section>
                    <section>
                        {i18n.language === 'zh_CN' ?
                            <Button ghost={true} onClick={() => setLang()} type="primary">English</Button>
                            : <Button ghost={true} onClick={() => setLang()} type="primary">中文</Button>}
                    </section>
                </div>
            </div>
        </header>
    );
};

export default Header;
