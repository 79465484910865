import $axios from '../../utilities/axios'
import getBaseUrl, {ApiHandler} from "../../../api/base";

export interface Permission {
    code: string
    name: string
    description?: string
}

export interface UserInfo {
    name: string
    email: string
    displayName?: string
    password?: string
    token: string
    permission: Permission[]
}

export interface UserSettings {
    qiniuBucket: string,
    qiniuAccessKey: string,
    qiniuSecretKey: string,
    qiniuEnable: boolean,
    qiniuUrlPrefix: string
}

export default {
    login(params?: object): any {
        return $axios.post(getBaseUrl() + '/api/login', params)
    },
    signup(captchaCode: string, params?: object): any {
        return $axios.post(getBaseUrl() + '/api/register/' + captchaCode, params)
    },
    forgetPwd(email: string, captchaComp: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/forgetPwd/' + email + "/" + captchaComp)
    },
    showResetPwd(code: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/showResetPwd/' + code)
    },
    resetPwd(params?: object): any {
        return ApiHandler.post(getBaseUrl() + '/api/resetPwd', params)
    },

    captcha(random?: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/captcha/?' + random)
    },
    saveUserSettings(params?: object): any {
        return ApiHandler.post(getBaseUrl() + '/api/saveUserSettings', params)
    },
    queryUserSettings(): any {
        return ApiHandler.get(getBaseUrl() + '/api/queryUserSettings')
    },
    queryArticles(): any {
        return ApiHandler.get(getBaseUrl() + '/api/articles')
    },
    deleteArticle(oId: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/delete/' + oId)
    },
    sync(params?: object): any {
        return ApiHandler.post(getBaseUrl() + '/api/sync', params)
    },

    queryArticleByOId(oid: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/article/edit/' + oid)
    },

    queryHistoriesByOId(oid: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/histories/' + oid)
    },

    queryHistoryById(id: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/history/' + id)
    },
    rollback(id: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/rollback/' + id)
    },
    publish(params?: object): any {
        return ApiHandler.post(getBaseUrl() + '/api/publish', params)
    },

    cancel(oid: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/cancel/' + oid)
    },
    queryPublishedArticleByOId(oid: string): any {
        return ApiHandler.get(getBaseUrl() + '/api/article/' + oid)
    },
}
