import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {asyncCall} from './logic/async-call.sliceLogic';
import {InitialStateTypes} from './../../../common/interfaces/slices/initial/initial.interface';
import Vditor from "vditor";
import {RootState} from "../../store";

export interface EditorTypes {
    myEditor: any;
    loading: boolean
}

// CONFIGURE THE INITIAL STATE::
// @ts-ignore
const initialState: EditorTypes = {
    myEditor: null,
    loading: true
};

// CREATE THE SLICE OF THE `Initial`::
export const editorSlice = createSlice({
    name: `editorSlice`,
    initialState,
    reducers: {
        setVditor: (state, action: PayloadAction<Vditor>) => {
            state.myEditor = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    }
});

// EXPORT THE ACTIONS::
export const {setVditor, setLoading} = editorSlice.actions;


// EXPORT THE `Initial` REDUCER::
export default editorSlice.reducer;
