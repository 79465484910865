import {Tabs, Radio, Form, Input, Button, message, Checkbox} from 'antd';
import * as React from "react";
import {setLng, setVTheme, setEditorMode, CommonObjectType} from "../../redux/slices/initial/initial.slice";
import {useHistory, useLocation} from "react-router";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useTranslation} from "react-i18next";
import {RootState} from "../../redux/store";
import {selectUserInfo, selectUserSettings, setUserSettings} from "../../redux/slices/initial/logic/userSlice";
import goSignin from './goSignin.gif'
import session from "../../common/interfaces/auth/session";
import {useDispatch} from "react-redux";
import setDataToLocalStorage from "../../common/utilities/localstorage-dealer/localstorage-setter.util";

const {TabPane} = Tabs;

const SettingPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();
    const userInfo = useAppSelector(selectUserInfo)
    const userSettings = useAppSelector(selectUserSettings)


    const {lng} = useAppSelector((state: RootState) => state.InitialSlice);
    const {editorMode} = useAppSelector((state: RootState) => state.InitialSlice);
    const {theme} = useAppSelector((state: RootState) => state.InitialSlice);
    const options = [
        {label: '中文', value: 'zh_CN'},
        {label: '英文', value: 'en_US'},
    ];

    const editorModeOptions = [
        {label: '所见即所得', value: 'wysiwyg'},
        {label: '即时渲染', value: 'ir'},
        {label: '分屏预览', value: 'sv'},
    ];
    const editorThemeOptions = [
        {label: '默认', value: 'classic'},
        {label: '暗黑', value: 'dark'},
    ];
    const onChangeLng = async (e: any) => {
        i18n.changeLanguage(e.target.value);
        dispatch(setLng(e.target.value))
    }
    const onChangeMode = async (e: any) => {
        dispatch(setEditorMode(e.target.value))
        setDataToLocalStorage('editor_mode_setting', e.target.value)
    }
    const onChangeVTheme = async (e: any) => {
        dispatch(setVTheme(e.target.value))
        //setDataToLocalStorage('type_theme_setting', e.target.value)
    }

    const onChangeTab = async (activeKey: string) => {

    }
    const onFinish = async (values: CommonObjectType<string>) => {
        try {
            const result = await session.saveUserSettings(values)
            if (userInfo && userInfo.token) {
                const result = await session.queryUserSettings()
                //  alert(JSON.stringify(result))
                dispatch(setUserSettings(result))
            }
            //sessionStorage.setItem('access_token', '1234')
        } catch (e) {

        }
        return
    }
    return (
        <main className="page">
            <section>
                <Tabs tabPosition='top' onChange={onChangeTab}>
                    <TabPane tab="语言设置" key="1">
                        <Radio.Group
                            options={options}
                            onChange={onChangeLng}
                            value={lng || 'zh_CN'}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </TabPane>
                    <TabPane tab="编辑器设置" key="2">
                        编辑器模式：<Radio.Group
                        options={editorModeOptions}
                        onChange={onChangeMode}
                        value={editorMode || 'sv'}
                        optionType="button"
                        buttonStyle="solid"
                    /> <br/> <br/>
                        编辑器主题：<Radio.Group
                        options={editorThemeOptions}
                        onChange={onChangeVTheme}
                        value={theme || 'classic'}
                        optionType="button"
                        buttonStyle="solid"
                    />
                    </TabPane>
                    <TabPane tab="图床设置" key="3">
                        {userInfo && userInfo.token ? <Form
                            name="七牛"
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            initialValues={{
                                qiniuBucket: userSettings && userSettings.qiniuBucket ? userSettings.qiniuBucket : ''
                                ,
                                qiniuAccessKey: userSettings && userSettings.qiniuAccessKey ? userSettings.qiniuAccessKey : ''
                                ,
                                qiniuSecretKey: userSettings && userSettings.qiniuSecretKey ? userSettings.qiniuSecretKey : ''
                                ,
                                qiniuUrlPrefix: userSettings && userSettings.qiniuUrlPrefix
                                ,
                                qiniuEnable: userSettings && userSettings.qiniuEnable && userSettings.qiniuEnable.toString() == 'true' ? true : false
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="空间名称"
                                name="qiniuBucket"
                                rules={[{required: true, message: 'Please input!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="ACCESS_KEY"
                                name="qiniuAccessKey"
                                rules={[{required: true, message: 'Please input!'}]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="SECRET_KEY"
                                name="qiniuSecretKey"
                                rules={[{required: true, message: 'Please input!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="URL前缀"
                                name="qiniuUrlPrefix"
                                rules={[{required: true, message: 'Please input!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="qiniuEnable" valuePropName="checked" wrapperCol={{offset: 8, span: 16}}>
                                <Checkbox>启用</Checkbox>
                            </Form.Item>
                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                            </Form.Item>
                        </Form> : <img src={goSignin}></img>}
                    </TabPane>
                    <TabPane tab="水印设置" key="4">
                        {userInfo && userInfo.token ? "表单" : <img src={goSignin}></img>}
                    </TabPane>
                </Tabs>
            </section>
        </main>
    );
};

export default SettingPage;

