import i18n from "i18next";
import enUsTrans from "./en-us.json";
import zhCnTrans from "./zh-cn.json";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next) // passes i18n down to react-i18next
 .
init({
    //引入资源文件
    resources: {
        en_US: {
            translation: enUsTrans,
        },
        zh_CN: {
            translation: zhCnTrans,
        },
    },
    lng: 'zh_CN',
    //选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: "zh_CN",
})
export default i18n;
