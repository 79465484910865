// pkgs:
import {Link, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactCanvasNest from 'react-canvas-nest';
import logo from './logo.jpeg'

// comps:

// utils:
import './style.sass';
import {SignInFormTypes} from '../../common/types/signin-form.types';
import React from "react";
import session from "../../common/interfaces/auth/session";
import {selectUserInfo, setUserInfo} from '../../redux/slices/initial/logic/userSlice'
import {LoginForm, ProFormText, ProFormCaptcha, ProFormCheckbox} from '@ant-design/pro-form';
import {
    UserOutlined,
    MailOutlined,
    MobileOutlined,
    LockOutlined,
    AlipayCircleOutlined,
    TaobaoCircleOutlined,
    WeiboCircleOutlined,
} from '@ant-design/icons';
import {message, Tabs, Space, Checkbox} from 'antd';
import {CSSProperties} from 'react';
import {CommonObjectType} from "../../redux/slices/initial/initial.slice";
import {useAppSelector} from "../../redux/hooks";
import { useTranslation } from 'react-i18next';
import {Form} from "antd";
import setDataToLocalStorage from "../../common/utilities/localstorage-dealer/localstorage-setter.util";
import CaptchaInput from "../../common/interfaces/comps/CaptchaInput";

type LoginType = 'phone' | 'account';

const iconStyles: CSSProperties = {
    marginLeft: '16px',
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: '24px',
    verticalAlign: 'middle',
    cursor: 'pointer',
};
// component>>>
const LoginPageV2: React.FC = () => {
    // comps info:
    const { t, i18n } = useTranslation();

    // preConfigured hooks:
    const history = useHistory();
    const dispatch = useDispatch();
    const userInfo = useAppSelector(selectUserInfo)
    const [needCaptcha, setNeedCaptcha] = useState('');
    const [change, setChange] = useState('');

    const [loginType, setLoginType] = useState<LoginType>('account');

    useEffect(() => {
        if (userInfo) {
            const {token} = userInfo
            if (token)
                history.push('/')
            return
        }
    }, [history, dispatch, userInfo])

    // 触发登录方法
    const onFinish = async (values: CommonObjectType<string>) => {
        const {email, password, captchaComp} = values
        try {
            // @ts-ignore
            let captchaCode = captchaComp === undefined ? '':captchaComp.captchaCode
            const result = await session.login({email, password, captchaCode})
            if (result && result.code === 200) {
                message.info(result.msg)
                setDataToLocalStorage('access_token', result.data.token)
                dispatch(setUserInfo(result.data))
                history.push('/')
            } else {
                if (!!result.data.need_captcha) {
                    setNeedCaptcha(result.data.need_captcha)
                    setChange(new Date().getTime() + "")
                }
                message.error(result.msg)
            }


        } catch (e) {
            const response = (e as any)?.response // Axios异常
            message.error(
                response
                    ? `发生错误:${response.data}`
                    : `认证服务异常,请联系管理员:${e}`
            )
        }
        return
    }

    return (
        <main className="page login-page">

            <ReactCanvasNest
                config={{
                    pointColor: '24,144,255',
                    lineColor: '24,144,255',
                    pointOpacity: 0.6
                }}
                style={{zIndex: -100}}
            />
            <section>
                <div className="header">
                    <h1>Tditor.com</h1>
                </div>
                <LoginForm
                    onFinish={onFinish}
                    //title="水墨"
                    //subTitle="全球最大同性交友网站"
                    // actions={
                    //     <Space>
                    //         其他登录方式
                    //         <AlipayCircleOutlined style={iconStyles}/>
                    //         <TaobaoCircleOutlined style={iconStyles}/>
                    //         <WeiboCircleOutlined style={iconStyles}/>
                    //     </Space>
                    // }
                >
                    <Tabs activeKey={loginType} onChange={(activeKey) => setLoginType(activeKey as LoginType)}>
                        <Tabs.TabPane key={'account'} tab={t('account_login')}/>
                        <Tabs.TabPane key={'phone'} tab={t('phone_login')}/>
                    </Tabs>
                    {loginType === 'account' && (
                        <>
                            <ProFormText
                                name="email"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <MailOutlined className={'prefixIcon'}/>,
                                }}
                                placeholder={'Email: example@qq.com'}
                                rules={[{

                                    pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,

                                    message: '邮箱格式不正确',

                                },
                                    {
                                        required: true,
                                        message: '请输入邮箱!',
                                    },
                                ]}
                            />
                            <ProFormText.Password
                                name="password"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className={'prefixIcon'}/>,
                                }}
                                placeholder={'请输入密码'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码！',
                                    },
                                ]}
                            />
                            {needCaptcha ?   <Form.Item name="captchaComp" rules={[
                                {
                                    validateTrigger: 'onBlur',
                                    validator: async (rule, value) => {
                                        // console.log(rule)
                                        if ('' === value.captchaCode) {
                                            throw new Error('请输入验证码!');
                                        }
                                    }
                                }


                            ]}>
                                <CaptchaInput change={change} url={"/api/captcha/" + needCaptcha + "/?"}/>
                            </Form.Item> : undefined}
                        </>
                    )}
                    {loginType === 'phone' && (
                        <>
                            <ProFormText
                                fieldProps={{
                                    size: 'large',
                                    prefix: <MobileOutlined className={'prefixIcon'}/>,
                                }}
                                name="mobile"
                                placeholder={'手机号'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号！',
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: '手机号格式错误！',
                                    },
                                ]}
                            />
                            <ProFormCaptcha
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className={'prefixIcon'}/>,
                                }}
                                captchaProps={{
                                    size: 'large',
                                }}
                                placeholder={'请输入验证码'}
                                captchaTextRender={(timing: any, count: any) => {
                                    if (timing) {
                                        return `${count} ${'获取验证码'}`;
                                    }
                                    return '获取验证码';
                                }}
                                name="captcha"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码！',
                                    },
                                ]}
                                onGetCaptcha={async () => {
                                    message.success('获取验证码成功！验证码为：1234');
                                }}
                            />
                        </>
                    )}
                    <div
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Checkbox checked>自动登录</Checkbox>

                        <Link style={{
                            float: 'right',
                        }} to="/forget-pwd">

                            忘记密码
                        </Link>

                    </div>
                </LoginForm>
                <p className="not-registered">
                    {t('tip_login')}
                    <Link to="/signup"> {t('registry')} </Link>
                    now!
                </p>
            </section>
        </main>
    );
};

export default LoginPageV2;
