// pkgs:
import { FaHeart } from 'react-icons/fa';
import { AiOutlineCoffee } from 'react-icons/ai';
import { VFC } from 'react';

// utils:
import './style.sass';
import { FooterInterface } from '../../../common/interfaces/comps/footer.interface';
import * as React from "react";

// comps:

// component>>>
const Footer: VFC<FooterInterface> = ({ expanded }) => {
  return (
    <footer className="default-footer">
        <p className="author">
            Built with
            <span className="icon">
            <FaHeart />
          </span>
            and
            <span className="icon">
            <AiOutlineCoffee />
          </span>
            in
            <span className="icon">
            <code>Beijing, China</code>
          </span>
        </p>
        <p className="reservation">
            <span className="icon">©</span>Copyright {new Date().getFullYear()}
            <span>
            <a href="https://www.6aiq.com" target="_blank noopener noreferer">
              @cbam
            </a>
          </span>
            All rights reserved.
        </p>
    </footer>
  );
};

export default Footer;
