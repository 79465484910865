import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../../store";
import {UserInfo, UserSettings} from "../../../../common/interfaces/auth/session";

export interface UserState {
    userInfo: UserInfo & { is_oidc_user: boolean }
    userSettings: UserSettings
}

const initialState: UserState = {
    userInfo: {
        name: '',
        email: '',
        displayName: '',
        permission: [],
        token: '',
        is_oidc_user: false,

    },
    userSettings: {
        qiniuBucket: '',
        qiniuAccessKey: '',
        qiniuSecretKey:  '',
        qiniuUrlPrefix: '',
        qiniuEnable: false
    }

}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        setUserSettings: (state, action) => {
            state.userSettings = action.payload
        }
    }
})

export const {setUserInfo, setUserSettings} = userSlice.actions

export const selectUserInfo = (state: RootState) => state.user.userInfo
export const selectUserSettings = (state: RootState) => state.user.userSettings

export default userSlice.reducer
