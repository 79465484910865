// pkgs:
import {Link, useHistory, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';

// comps:
import {
    UserOutlined,
    MailOutlined,
    LockOutlined,
    AlipayCircleOutlined,
    TaobaoCircleOutlined,
    WeiboCircleOutlined,
} from '@ant-design/icons';
// utils:
import { SignupFormTypes } from '../../common/types/signup-form.types';
import ReactCanvasNest from "react-canvas-nest";
import {ProFormText} from "@ant-design/pro-form";
import {Button, message} from "antd";
import {useState} from "react";
import {CommonObjectType} from "../../redux/slices/initial/initial.slice";
import session from "../../common/interfaces/auth/session";
import {setUserInfo} from "../../redux/slices/initial/logic/userSlice";
import Form from "antd/es/form/Form";
import {useTranslation} from "react-i18next";
import * as React from "react";

// component>>>
const ResetPwdPage: React.FC = (props) => {
  // comps info:
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] =  useState<string>('');

  // preConfigured hooks:
  const history = useHistory();
  const dispatch = useDispatch();

    const params = useParams();
    // @ts-ignore
    const {code} = params;
    React.useEffect(() => {
        const onFinish = async () => {
            try {

                const result = await session.showResetPwd(code)
                if (result) {
                    setEmail(result.email)
                    setName(result.name)
                    // @ts-ignore
                   // this.props.form.setFieldsValue({email: result.email})
                }
            } catch (e) {

            }
            return
        }
        onFinish()
    }, [this])
    const onFinish = async (values: CommonObjectType<string>) => {
        const {email, name, password} = values
        try {
            const result = await session.resetPwd({code, email, name, password})
            //sessionStorage.setItem('access_token', '1234')
            if (result) {
                history.push("/")
            }

        } catch (e) {
            const response = (e as any)?.response // Axios异常
            message.error(
                response
                    ? `发生错误:${response.data}`
                    : `认证服务异常,请联系管理员:${e}`
            )
        }
        return
    }
  return (
      <main className="page login-page">
          <ReactCanvasNest
              config={{
                  pointColor: '24,144,255',
                  lineColor: '24,144,255',
                  pointOpacity: 0.6
              }}
              style={{zIndex: -100}}
          />
          <section>
              <div className="header">
                  <h1>Tditor.com</h1>
              </div>
              <Form
                  name="normal_login"
                  onFinish={onFinish}
                  style={{"width": '328px'}}

              >
                  <ProFormText
                      name="name"
                      disabled
                      fieldProps={{
                          size: 'large',
                          prefix: <UserOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={name}
                      rules={[
                          {
                              required: false,
                              message: '请输入用户名!',
                          },
                      ]}
                  />
                  <ProFormText
                      name="email"
                      disabled
                      fieldProps={{
                          size: 'large',
                          prefix: <MailOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={email}
                      rules={[{

                          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,

                          message: '邮箱格式不正确',

                      },
                          {
                              required: false,
                              message: '请输入邮箱!',
                          },
                      ]}
                  />
                  <ProFormText.Password
                      name="password"
                      fieldProps={{
                          size: 'large',
                          prefix: <LockOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={'请输入密码'}
                      rules={[
                          {
                              required: true,
                              message: '请输入密码！',
                          },
                      ]}
                  />
                  <Button type="primary" htmlType="submit" block className="ant-btn ant-btn-primary ant-btn-lg">
                      确认重置密码
                  </Button>
              </Form>

              <p className="not-registered">
                  已有账号，去
                  <Link to="/signin">{t('login')}</Link>
              </p>
          </section>
      </main>
  );
};

export default ResetPwdPage;
