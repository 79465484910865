// pkgs:

// utils:
import { FormInputInterface } from '../../../common/interfaces/comps/form-input.interface';
import './style.sass';

// comps:

// component>>>
const FormInput: React.VFC<FormInputInterface> = ({
  type,
  value,
  inputName,
  label,
  collectInputData,
  readonly,
  ...otherProps
}) => {
  return (
    <div className="input-wrapper">
      {/*<label htmlFor={inputName}>{label}</label>*/}
      <input
          style={{'textAlign': 'center'}}
        type={type}
        name={inputName}
        value={value}
        readOnly={readonly}
        placeholder={'请输入标题'}
        {...otherProps}
        onChange={(e) => collectInputData(e.target.name, e.target.value)}
      />
    </div>
  );
};

export default FormInput;
