// pkgs:
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// comps:
import {
    UserOutlined,
    MailOutlined,
    LockOutlined,
    AlipayCircleOutlined,
    TaobaoCircleOutlined,
    WeiboCircleOutlined,
} from '@ant-design/icons';
// utils:
import './style.sass';
import { SignupFormTypes } from '../../common/types/signup-form.types';
import ReactCanvasNest from "react-canvas-nest";
import {ProFormText} from "@ant-design/pro-form";
import {Button, message} from "antd";
import React, {useState} from "react";
import {CommonObjectType} from "../../redux/slices/initial/initial.slice";
import session from "../../common/interfaces/auth/session";
import {setUserInfo} from "../../redux/slices/initial/logic/userSlice";
import {Form} from "antd";
import {useTranslation} from "react-i18next";
import CaptchaInput from "../../common/interfaces/comps/CaptchaInput";

// component>>>
const SignupPage = () => {
  // comps info:
    const { t, i18n } = useTranslation();
    const [change, setChange] = useState('');

  // preConfigured hooks:
  const history = useHistory();
  const dispatch = useDispatch();

    // 触发登录方法
    const onFinish = async (values: CommonObjectType<string>) => {
        const {email, name, password, captchaComp} = values
        try {
            const result = await session.signup(JSON.parse(JSON.stringify(captchaComp)).captchaCode, {email, name, password})
            if (result && result.code === 200) {
                message.info(result.msg)
                history.push('/signin')
            } else {
                setChange(new Date().getTime() + "")
                message.error(result.msg)
            }


        } catch (e) {
            const response = (e as any)?.response // Axios异常
            message.error(
                response
                    ? `发生错误:${response.data}`
                    : `认证服务异常,请联系管理员:${e}`
            )
        }
        return
    }
  return (
      <main className="page login-page">
          <ReactCanvasNest
              config={{
                  pointColor: '24,144,255',
                  lineColor: '24,144,255',
                  pointOpacity: 0.6
              }}
              style={{zIndex: -100}}
          />
          <section>
              <div className="header">
                  <h1>Tditor.com</h1>
              </div>
              <Form
                  name="normal_login"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  style={{"width": '328px'}}

              >
                  <ProFormText
                      name="name"
                      fieldProps={{
                          size: 'large',
                          prefix: <UserOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={'用户名: zhangsan'}
                      rules={[
                          {
                              required: true,
                              message: '请输入用户名!',
                          },
                      ]}
                  />
                  <ProFormText
                      name="email"
                      fieldProps={{
                          size: 'large',
                          prefix: <MailOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={'Email: example@qq.com'}
                      rules={[{

                          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,

                          message: '邮箱格式不正确',

                      },
                          {
                              required: true,
                              message: '请输入邮箱!',
                          },
                      ]}
                  />
                  <ProFormText.Password
                      name="password"
                      fieldProps={{
                          size: 'large',
                          prefix: <LockOutlined className={'prefixIcon'}/>,
                      }}
                      placeholder={'请输入密码'}
                      rules={[
                          {
                              required: true,
                              message: '请输入密码！',
                          },
                      ]}
                  />
                  <Form.Item name="captchaComp" rules={[
                      {
                          validateTrigger: 'onBlur',
                          validator: async (rule, value) => {
                              // console.log(rule)
                              if ('' === value.captchaCode) {
                                  throw new Error('请输入验证码!');
                              }
                          }
                      }


                  ]}>
                      <CaptchaInput change={change}/>
                  </Form.Item>
                  <Button type="primary" htmlType="submit" block className="ant-btn ant-btn-primary ant-btn-lg">
                      {t('registry')}
                  </Button>
              </Form>

              <p className="not-registered">
                  已有账号，去
                  <Link to="/signin">{t('login')}</Link>
              </p>
          </section>
      </main>
  );
};

export default SignupPage;
