import {useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import Vditor from "vditor";
import {message} from "antd";

export const exportMarkdown = (type: string, myEditor: Vditor) => {
    let arr = document.getElementsByTagName('button');
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].innerHTML.toString() == type) {
            arr[i].click()
            break;
        }
    }
}

export const exportPDF = (type: string, myEditor: Vditor) => {
    message.info("生成中...")

    if (type === 'PDF') {
        const iframe = document.querySelector("iframe");
        if (!iframe) {
            message.info("没有可导出的东西")
            return
        }
        // @ts-ignore
        let style = myEditor != undefined && myEditor.vditor.options.preview.hljs.style
        // @ts-ignore
        let contentTheme = myEditor != undefined && myEditor.vditor.options.preview.theme.current
        // @ts-ignore
        iframe.contentDocument.open();
        // @ts-ignore
        // @ts-ignore
        iframe.contentDocument.write(`<link rel="stylesheet" href="https://tditor.com/cdn/dist/index.css"/>
<script src="https://tditor.com/cdn/dist/method.min.js"></script>
<body>
<div id="preview"></div>

</body>
<script>
window.addEventListener("message", (e) => {
  if(!e.data) {
    return;
  }
  Vditor.preview(document.getElementById('preview'), e.data, {
   cdn: 'https://tditor.com/cdn/',
   theme: {
                    current: '${contentTheme}',
                    list: { "ant-design": "Ant Design", dark: "Dark", light: "Light", wechat: "WeChat", '姹紫': '姹紫', '橙心': '橙心' },
                    path: 'https://tditor.com/cdn/dist/css/content-theme'
                },
   markdown: {
                    toc: "${true}",
                },
                
    hljs: {
      style: "${style}"
    },
    transform(html) {
       let element = document.createElement("div");
       element.innerHTML = html;
       let blockquote = element.getElementsByTagName("blockquote")
                    for (let k = 0; k < blockquote.length; k++) {
                        blockquote[k].setAttribute("class", "multiquote-1")
                    }
                    for (let j = 1; j <= 3; j++) {
                        let arr = element.getElementsByTagName('h' + j);
                        for (let i = 0; i < arr.length; i++) {
                            let span = document.createElement("span");
                            let prefixSpan = document.createElement("span");
                            let suffixSpan = document.createElement("span");
                            span.setAttribute('class', 'content')
                            prefixSpan.setAttribute('class', 'prefix')
                            suffixSpan.setAttribute('class', 'suffix')
                            let t = document.createTextNode(arr[i].innerHTML);
                            span.appendChild(t)
                            arr[i].innerHTML = ''
                            arr[i].appendChild(prefixSpan)
                            arr[i].appendChild(span);
                            arr[i].appendChild(suffixSpan);
                        }
                    }
                    html = element.outerHTML.toString();
                    return html
                },
                after() {
 
                }
  });
  setTimeout(() => {
        window.print();
    }, 3600);
}, false);
</script>`);
        // @ts-ignore
        iframe.contentDocument.close();
        setTimeout(() => {
            // @ts-ignore
            iframe.contentWindow.postMessage(myEditor.getValue(), "*");
        }, 200);
    }

    // let arr = document.getElementsByTagName('button');
    // for (let i = 0; i < arr.length; i++) {
    //     if (arr[i].innerHTML.toString() == type) {
    //         arr[i].click()
    //         break;
    //     }
    // }
}




export const exportHTML = (vditor: Vditor) => {
    message.info("生成中...")

    // @ts-ignore
    let contentTheme = vditor != undefined && vditor.vditor.options.preview.theme.current
    if (!vditor) {
        message.info("没有可导出的东西")
        return
    }
    // @ts-ignore
    let hljs = vditor.vditor.options.preview.hljs

    // @ts-ignore
    let math = vditor.vditor.options.preview.math
    const content = vditor.getHTML();

    // @ts-ignore
    const html = `<html><head><link rel="stylesheet" type="text/css" href="${vditor.vditor.options.cdn}/dist/index.css"/>
<link rel="stylesheet" href="https://tditor.com/cdn/dist/css/content-theme/${contentTheme}.css"/>

<script src="${vditor.vditor.options.cdn}/dist/method.min.js"></script></head>
<body><div class="vditor-reset" id="preview">${content}</div>
<script>
    const previewElement = document.getElementById('preview')
    Vditor.setContentTheme('${contentTheme}', '${vditor.vditor.options.cdn}');
    Vditor.codeRender(previewElement, '${vditor.vditor.options.lang}');
    Vditor.highlightRender(${JSON.stringify(hljs)}, previewElement, '${vditor.vditor.options.cdn}');
    Vditor.mathRender(previewElement, {
        cdn: '${vditor.vditor.options.cdn}',
        math: ${JSON.stringify(math)},
    });
    Vditor.mermaidRender(previewElement, ".language-mermaid", '${vditor.vditor.options.cdn}');
    Vditor.graphvizRender(previewElement, '${vditor.vditor.options.cdn}');
    Vditor.chartRender(previewElement, '${vditor.vditor.options.cdn}');
    Vditor.mindmapRender(previewElement, '${vditor.vditor.options.cdn}');
    Vditor.abcRender(previewElement, '${vditor.vditor.options.cdn}');
    Vditor.mediaRender(previewElement);
</script></body></html>`;
    download(vditor, html, content.substr(0, 10) + ".html");
};
export const download = (vditor: Vditor, content: string, filename: string) => {

    let element = document.createElement("div");
    element.innerHTML = content;
    element.setAttribute("class", "vditor-reset")
    let blockquote = element.getElementsByTagName("blockquote")
    for (let k = 0; k < blockquote.length; k++) {
        blockquote[k].setAttribute("class", "multiquote-1")
    }
    for (let j = 1; j <= 3; j++) {
        let arr = element.getElementsByTagName('h' + j);
        for (let i = 0; i < arr.length; i++) {
            let span = document.createElement("span");
            let prefixSpan = document.createElement("span");
            let suffixSpan = document.createElement("span");
            span.setAttribute('class', 'content')
            prefixSpan.setAttribute('class', 'prefix')
            suffixSpan.setAttribute('class', 'suffix')
            let t = document.createTextNode(arr[i].innerHTML);
            span.appendChild(t)
            arr[i].innerHTML = ''
            arr[i].appendChild(prefixSpan)
            arr[i].appendChild(span);
            arr[i].appendChild(suffixSpan);
        }
    }
    content = element.outerHTML.toString();



    const aElement = document.createElement("a");
    if ("download" in aElement) {
        aElement.download = filename;
        aElement.style.display = "none";
        aElement.href = URL.createObjectURL(new Blob([content]));

        document.body.appendChild(aElement);
        aElement.click();
        aElement.remove();
    } else {
        message.success("导出成功")

    }
};
