// pkgs: installed libs like react, axios etc..
import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import { Helmet } from 'react-helmet';
import {
   SmileOutlined
} from '@ant-design/icons';
// utils: utilities that you prepared your self
import {store, persistor, RootState} from './redux/store';
import './common/styles/index.sass';
import App from './App';
import {PersistGate} from "redux-persist/integration/react";
import zh_CN from 'antd/lib/locale/zh_CN';
import {ConfigProvider} from "antd";
let appReady = Promise.resolve()

const tableEmpty = ()=> {
   return <div>
       <div style={{ textAlign: 'center' }}>
           <SmileOutlined style={{ fontSize: 20 }} />
           <p>登陆后管理我的文档</p>
       </div>
    </div>
}
appReady.then(async () => {
    // store.dispatch(setUserInfo(provided);

    ReactDOM.render(
        <StrictMode>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <ConfigProvider renderEmpty={tableEmpty} locale={zh_CN}>
                            <Helmet>
                                <title>Tditor - 最好用的在线Markdown编辑器</title>
                                <link rel="canonical" href="https://tditor.com" />
                            </Helmet>
                            <App />
                        </ConfigProvider>
                    </Router>
                </PersistGate>
            </ReduxProvider>
        </StrictMode>,
        document.getElementById('root')
    )

})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
