// pkgs:
import Axios from 'axios';
import {store} from '../redux/store'
import {HashRouter} from 'react-router-dom'
import {message} from 'antd'

// utils:
import {objGetter, stringGetter} from '../common/utilities/localstorage-dealer/localstorage-getters.util';
import {CommonObjectType} from "../redux/slices/initial/initial.slice";
import {setUserInfo} from "../redux/slices/initial/logic/userSlice";
import setDataToLocalStorage from "../common/utilities/localstorage-dealer/localstorage-setter.util";

// the connection base:
let baseUrl: string;

// all potential locals
const LOCALS: string[] = [`localhost`, `127.0.0.1`, ``];

// check wether the app hosted on local or not:
if (LOCALS.includes(window.location.hostname)) {
    baseUrl = `http://localhost:8081`;
} else {
    baseUrl = `https://tditor.com`;
}
const getBaseUrl = () => {
    if (LOCALS.includes(window.location.hostname)) {
        return `http://localhost:8081`;
    } else {
        return `https://tditor.com`;
    }
}
export default getBaseUrl

const router: CommonObjectType = new HashRouter({})

// token失效，清除用户信息并返回登录界面
const clearAll = () => {
    store.dispatch(setUserInfo({}))
    localStorage.removeItem("access_token");
    //router.history.push("/signin")
}
// >>>> create axios instance:
interface AxiosConfig {
    timeout: number
    headers: {
        'Content-Type': string
    }
}

const config: AxiosConfig = {
    timeout: 600000,
    headers: {
        'Content-Type': 'application/json'
    }
}

export const ApiHandler = Axios.create(config)
// make a verification with every future sent req
ApiHandler.interceptors.request.use((req) => {
    const token = stringGetter(`access_token`);
    if (token) {
        req.headers.Authorization = token;
    }

    return req;
});

// 返回后拦截
ApiHandler.interceptors.response.use(
    (response): Promise<any> => {
        const {data} = response
        if (data) {
            if (data.code != 200 && data.code != 202) {
                if (data.code === 403) {
                    message.info(data.msg)
                    clearAll()
                    return Promise.resolve(data)
                }
                message.error(data.msg)
                return Promise.resolve(data)
            } else {
                if (data.code == 202) {
                    return Promise.resolve(data.data)
                }
                message.info(data.msg)
                return Promise.resolve(data.data)
            }
        }
        return Promise.reject(response)
    },
    (err) => {
        try {
            if (JSON.stringify(err).includes('403')) {
                clearAll()
            }
        } catch (error) {
            clearAll()
        }
        message.destroy()
        return Promise.reject(err)
    }
)

