// pkgs:

// comps:

// utils:
import VEditor from "../VEditor";
import * as React from "react";
import {Pane} from "react-split-pane";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {selectUserInfo, setUserInfo} from "../../redux/slices/initial/logic/userSlice";
import './article.css'
import {
    BarsOutlined, CloudDownloadOutlined, ArrowRightOutlined, HomeOutlined, FilePdfOutlined, Html5Outlined,
    FileMarkdownOutlined, SettingOutlined, DownOutlined, SmileOutlined
} from '@ant-design/icons';
import defaultText from "../default";
import Vditor from "vditor";
import session from "../../common/interfaces/auth/session";
import {Button, Dropdown, message, Space} from "antd";
import {useHistory, useParams} from "react-router";
import {Link} from "react-router-dom";
import i18nNext from "../../common/language";
import {TextLoop} from "react-text-loop-next";
import FormInput from "../../components/distributed/form-input/form-input.comp";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Menu} from "antd";
import {exportHTML, exportMarkdown, exportPDF} from "../export/export";
import {setLng} from "../../redux/slices/initial/initial.slice";
import {Helmet} from 'react-helmet';

// component>>>
const ArticlePage: React.FC = (props) => {
    const userInfo = useAppSelector(selectUserInfo)
    const dispatch = useAppDispatch();
    const params = useParams();
    // @ts-ignore
    const {oId} = params;
    const [title, setTitle] = useState<string>('');
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const logout = async () => {
        dispatch(setUserInfo({}))
        localStorage.removeItem("access_token");
        history.replace({pathname: '/'})
    }
    const menu = (
        <Menu>
            <Menu.Item onClick={logout}>
                <SmileOutlined/><span>退出登录</span>
            </Menu.Item>
        </Menu>
    )
    const settingMenu = (
        <Menu>
            {/*<Menu.Item onClick={() => exportPDF('PDF', undefined)}>*/}
            {/*<FilePdfOutlined/><span> 导出PDF</span>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item onClick={() => exportMarkdown('Markdown', undefined)}>*/}
            {/*<FileMarkdownOutlined/><span> 导出Markdown</span>*/}
            {/*</Menu.Item>*/}
            {/*<Menu.Item onClick={() => exportHTML(undefined)}>*/}
            {/*<Html5Outlined/><span> 导出HTML</span>*/}
            {/*</Menu.Item>*/}

        </Menu>
    )
    const otherMenu = (
        <Menu
            items={[
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1654416793853">
                            Markdown教程
                        </a>
                    ),
                    key: '0',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1653999657891">
                            PR/FAQ
                        </a>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1655035915418#/">
                            更新日志
                        </a>
                    ),
                    key: '3',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://tditor.com/article/1653999657891">
                            关于我们
                        </a>
                    ),
                    key: '2',
                },
                {
                    label: (
                        <a target="_blank" rel="noopener noreferrer" href="https://support.qq.com/product/408537">
                            反馈&建议
                        </a>
                    ),
                    key: '4',
                }
            ]}
        />
    );
    const setLang = () => {
        let lang = 'zh_CN';
        if (i18n.language === 'zh_CN') {
            lang = 'en_US';
        }
        if (i18n.language === 'en_US') {
            lang = 'zh_CN';
        }
        i18n.changeLanguage(lang);
        dispatch(setLng(lang))
    };
    let md = defaultText;
    React.useEffect(() => {
        let contentTheme = '姹紫'
        const onFinish = async () => {
            try {

                const result = await session.queryPublishedArticleByOId(oId)

                if (result) {
                    md = result.content;
                    contentTheme = result.contentTheme
                    setTitle(result.title)
                } else {
                    message.error("拉取文章失败")
                }
            } catch (e) {
                const response = (e as any)?.response // Axios异常
                message.error(
                    response
                        ? `发生错误:${response.data}`
                        : `认证服务异常,请联系管理员:${e}`
                )
            }


            const outlineElement = document.getElementById('outline')
            const previewElement = document.getElementById('preview')
            // @ts-ignore
            Vditor.preview(previewElement, md, {
                markdown: {
                    toc: true,
                },
                speech: {
                    enable: true,
                },
                theme: {
                    current: contentTheme,
                    list: {
                        "ant-design": "Ant Design",
                        dark: "Dark",
                        light: "Light",
                        wechat: "WeChat",
                        '姹紫': '姹紫',
                        '橙心': '橙心'
                    },
                    path: 'https://tditor.com/cdn/dist/css/content-theme'
                },
                cdn: 'https://tditor.com/cdn',
                anchor: 1,
                after() {
                    if (window.innerWidth <= 768) {
                        return
                    }
                    console.time('time');
                    let element = previewElement;
                    // @ts-ignore
                    let blockquote = element.getElementsByTagName("blockquote")
                    for (let k = 0; k < blockquote.length; k++) {
                        blockquote[k].setAttribute("class", "multiquote-1")
                    }
                    // // @ts-ignore
                    // let section = element.getElementsByClassName("vditor-reset")
                    // section[0].setAttribute("edit-tool", 'Tditor - 在线Markdown编辑器')
                    // section[0].setAttribute("tool-website", 'https://www.tditor.com')
                    for (let j = 1; j <= 3; j++) {
                        // @ts-ignore
                        let arr = element.getElementsByTagName('h' + j);
                        for (let i = 0; i < arr.length; i++) {
                            let span = document.createElement("span");
                            let prefixSpan = document.createElement("span");
                            let suffixSpan = document.createElement("span");
                            span.setAttribute('class', 'content')
                            prefixSpan.setAttribute('class', 'prefix')
                            suffixSpan.setAttribute('class', 'suffix')
                            // @ts-ignore
                            span.innerText = (arr[i].getAttribute("id"))
                            arr[i].innerHTML = ''
                            arr[i].appendChild(prefixSpan)
                            arr[i].appendChild(span);
                            arr[i].appendChild(suffixSpan);
                        }
                    }
                    console.timeEnd('time');


                    // @ts-ignore
                    Vditor.outlineRender(previewElement, outlineElement)
                    // @ts-ignore
                    if (outlineElement.innerText.trim() !== '') {
                        // @ts-ignore
                        outlineElement.style.display = 'block'
                    }
                },
                lazyLoadImage: 'https://tditor.com/cdn/dist/images/img-loading.svg',
                renderers: {
                    renderHeading: (node, entering) => {
                        const id = Lute.GetHeadingID(node)
                        if (entering) {
                            return [
                                `<h${node.__internal_object__.HeadingLevel} id="${id}" class="vditor__heading"><span class="prefix"></span><span>`,
                                Lute.WalkContinue]
                        } else {
                            return [
                                `</span><a id="vditorAnchor-${id}" class="vditor-anchor" href="#${id}"><svg viewBox="0 0 16 16" version="1.1" width="16" height="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a></h${node.__internal_object__.HeadingLevel}>`,
                                Lute.WalkContinue]
                        }
                    },
                },
            })

            return
        }
        onFinish()


    }, [])
    return (
        <div>
            <Helmet>
                <title>{title + ' - Tditor'}</title>
                <meta
                    name="description"
                    content={md.substr(0, 20)}
                />
                <link rel="canonical" href={"https://tditor.com/article/" + oId}/>
            </Helmet>
            <header className="default-header">
                <div style={{height: `inherit`}}>
                    <div className="header-wrapper">
                        <section className="left-wing">
                            <div className="logo">
                                <Link to="/">

                                </Link>

                            </div>
                        </section>
                        <section className="left-name">

                            <h4>
                                <span className="head-text"> {i18nNext.t('title')}</span>
                            </h4>
                        </section>

                        <section style={{'flexGrow': 100}}>
                            <FormInput
                                type="text"
                                value={title}
                                label=""
                                readonly={true}
                                inputName="title"
                                collectInputData={(name: string, value: string) => {

                                }
                                }
                            />
                        </section>
                        <section>
                            <Dropdown overlay={settingMenu}>
                                <Space>
                                    <span><CloudDownloadOutlined style={{'fontSize': '25px'}}/></span>
                                </Space>
                            </Dropdown>
                        </section>

                        <section>
                            <Dropdown overlay={otherMenu}>
                                <Space>
                                    {/*<span><img style={{'width': '25px', 'cursor': 'pointer'}} src={feedback} alt=""/> </span>*/}
                                    <BarsOutlined style={{'fontSize': '25px', 'cursor': 'pointer'}}/>
                                </Space>
                            </Dropdown>
                        </section>
                        <section>
                            {userInfo && userInfo.token ? <Dropdown overlay={menu}>
                                    <Space>
                                        {/*<HomeOutlined style={{'fontSize': '25px'}}/>*/}
                                        <span>{'您好!' + userInfo.name}</span>
                                        <DownOutlined/>
                                    </Space>
                                </Dropdown> :
                                <Button onClick={() => history.push("/signin")} type="primary">{t('login')}</Button>
                            }
                        </section>
                        <section>
                            {i18n.language === 'zh_CN' ?
                                <Button ghost={true} onClick={() => setLang()} type="primary">English</Button>
                                : <Button ghost={true} onClick={() => setLang()} type="primary">中文</Button>}
                        </section>
                    </div>
                </div>
            </header>
            <main className="page landing-page">
                <div id="previewWrap">
                    <div id="preview" className="preview"></div>
                </div>
                <div id="outline"></div>
            </main>
        </div>

    );
};

export default ArticlePage;
