import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {asyncCall} from './logic/async-call.sliceLogic';
import {InitialStateTypes} from './../../../common/interfaces/slices/initial/initial.interface';
import Vditor from "vditor";
import {RootState} from "../../store";

// CONFIGURE THE INITIAL STATE::
// @ts-ignore
const initialState: InitialStateTypes = {
    val: 0,
    stage: `idle`,
    failureMsg: null,
    theme: 'classic',
    myEditor: null,
    lng: 'zh_CN',
    editorMode: 'sv',
    contentTheme: 'light',

    currentIdx: 'default',
    notification: true,

};
export type CommonObjectType<T = any> = Record<string, T>

// CREATE THE SLICE OF THE `Initial`::
export const initialSlice = createSlice({
    name: `initialSlice`,
    initialState,
    reducers: {
        increment: (state) => {
            state.val += 1;
        },
        decrement: (state) => {
            state.val -= 1;
        },
        incrementByAmount: (state, action: PayloadAction<number>) => {
            state.val += action.payload;
        },
        setVTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
        setContentTheme: (state, action: PayloadAction<string>) => {
            state.contentTheme = action.payload;
        },
        setLng: (state, action: PayloadAction<string>) => {
            state.lng = action.payload;
        },
        setEditorMode: (state, action: PayloadAction<string>) => {
            state.editorMode = action.payload;
        },

        setVditor: (state, action: PayloadAction<Vditor>) => {
            state.myEditor = action.payload;
        },
        setCurrentIdx:  (state, action: PayloadAction<string>) => {
            state.currentIdx = action.payload;
        },
        setNotification:  (state, action: PayloadAction<boolean>) => {
            state.notification = action.payload;
        },
    },

    // ADD EXTRA REDUCERS::
    extraReducers: (builder) => {
        builder
            .addCase(asyncCall.pending, (state) => {
                state.stage = `busy`;
            })
            .addCase(asyncCall.fulfilled, (state) => {
                state.stage = `idle`;
                state.val += 100;
            })
            .addCase(asyncCall.rejected, (state, action) => {
                state.stage = `failed`;
                state.failureMsg = `Failed to count, ${action.payload}`;
            });
    },
});

// EXPORT THE ACTIONS::
export const {increment, decrement, incrementByAmount, setCurrentIdx, setVTheme, setContentTheme, setLng, setEditorMode, setNotification} = initialSlice.actions;

export const selectVThemeInfo = (state: RootState) => state.InitialSlice.theme
export const selectContentThemeInfo = (state: RootState) => state.InitialSlice.contentTheme

// EXPORT THE `Initial` REDUCER::
export default initialSlice.reducer;
