// pkgs: installed libs like react, axios etc..
import {Route, Switch} from 'react-router-dom';
import React, {useState, createContext, useContext} from 'react';
import 'antd/dist/antd.css';
// utils: utilities that you prepared your self
import './common/styles/app.sass';
import ScrollToTop from './common/utilities/scroll-to-top/scroll-to-top.util';
import {BrowserRouter as Router} from 'react-router-dom';

// comps:
import Layout from './components/distributed/layout/layout.comp';
import i18n from "./common/language";

// views/pages:
import LandingPage from './views/landing/landing.page';
import NotFound from './views/not-found/notfound.page';
import Vditor from "vditor";
import CommonContext from './common/CommonContext';
import LoginPage from "./views/signin/signin.page";
import LoginPageV2 from "./views/signin/signin.page";
import SignupPage from "./views/signup/signup.page";
import {Phone} from "showcase-components";
import SettingPage from "./views/setting/setting.page";
import session from "./common/interfaces/auth/session";
import {useDispatch} from "react-redux";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {selectUserInfo, setUserInfo} from "./redux/slices/initial/logic/userSlice";
import ArticlePage from "./views/landing/ArticlePage";
import ForgetPwdPage from "./views/resetPwd/forget.pwd.page";
import ResetPwdPage from "./views/resetPwd/reset.pwd.page";

// component>>>
const App = () => {
        return (
            <ScrollToTop>
                <Switch>
                    <Route exact path="/signin">
                        <Layout expanded>
                            <LoginPageV2/>
                        </Layout>
                    </Route>
                    <Route exact path="/signup">
                        <Layout expanded>
                            <SignupPage/>
                        </Layout>
                    </Route>
                    <Route exact path="/forget-pwd">
                        <Layout expanded>
                            <ForgetPwdPage/>
                        </Layout>
                    </Route>
                    <Route exact path="/reset-pwd/:code">
                        <Layout expanded>
                            <ResetPwdPage/>
                        </Layout>
                    </Route>
                    <Route exact path="/settings">
                        <Layout expanded>
                            <SettingPage/>
                        </Layout>
                    </Route>
                    <Route exact path="/article/:oId">
                        <ArticlePage/>
                    </Route>
                    <Route exact path="/">
                        <Layout expanded>
                            <LandingPage/>
                        </Layout>

                    </Route>

                    {/* 404 */}
                    <Route path="*">
                        <Layout expanded>
                            <NotFound/>
                        </Layout>
                    </Route>
                </Switch>
            </ScrollToTop>
        )
    }
;

export default App;
