import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {asyncCall} from './logic/async-call.sliceLogic';
import {InitialStateTypes} from './../../../common/interfaces/slices/initial/initial.interface';
import Vditor from "vditor";
import {RootState} from "../../store";
import {ArticleHistoryTypes, ArticleTypes} from "../../../common/types/article.types";

export interface ArticleListTypes {
    articleList : ArticleTypes[],
    currentIdx: string,
    currentTitle: string,
    editTitle: string,
    timestamp: string,
    articleListLoading: boolean,
}

// CONFIGURE THE INITIAL STATE::
// @ts-ignore
const initialState: ArticleListTypes = {
    articleList: [],
    currentIdx: 'default',
    currentTitle: '',
    editTitle: '',
    timestamp: '',
    articleListLoading: true,
};

// CREATE THE SLICE OF THE `Initial`::
export const articleSlice = createSlice({
    name: `articleSlice`,
    initialState,
    reducers: {
        setArticleList: (state, action: PayloadAction<ArticleTypes[]>) => {
            state.articleList = action.payload;
        },
        setCurrentIdx:  (state, action: PayloadAction<string>) => {
            state.currentIdx = action.payload;
        },
        setCurrentTitle:  (state, action: PayloadAction<string>) => {
            state.currentTitle = action.payload;
        },
        setEditTitle:  (state, action: PayloadAction<string>) => {
            state.editTitle = action.payload;
        },
        setTimestamp:  (state, action: PayloadAction<string>) => {
            state.timestamp = action.payload;
        },

        setArticleLoading:  (state, action: PayloadAction<boolean>) => {
            state.articleListLoading = action.payload;
        },
    }
});

// EXPORT THE ACTIONS::
export const {setTimestamp, setArticleList, setCurrentIdx, setCurrentTitle, setArticleLoading, setEditTitle} = articleSlice.actions;


// EXPORT THE `Initial` REDUCER::
export default articleSlice.reducer;
